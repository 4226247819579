import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
} from "react";
import cn from "classnames";

export type PopupItemType = "text" | "divider" | "button";

export type PopupItem = {
  type: PopupItemType;
  text?: string;
  onClick?: () => void;
};

export function TextItem({ text }: { text: string }) {
  return (
    <p className="block w-full text-left px-4 py-2 text-sm text-gray-700">
      {text}
    </p>
  );
}

export function DividerItem() {
  return <hr className="border-gray-200 py-0.5" />;
}

export function ButtonItem({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <button
      className="block w-full text-left px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default function Popup({
  children,
  items,
  className,
}: {
  children: React.ReactNode;
  items: PopupItem[];
  className?: string;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDropdown && containerRef.current && dropdownRef.current) {
      const buttonRect = containerRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      
      // 计算水平居中位置
      const buttonCenter = buttonRect.left + buttonRect.width / 2;
      const dropdownHalfWidth = dropdownRect.width / 2;
      
      let finalLeft = buttonCenter - dropdownHalfWidth;
      
      // 检查左右边界
      if (finalLeft < 16) {
        finalLeft = 16;
      } else if (finalLeft + dropdownRect.width > window.innerWidth - 16) {
        finalLeft = window.innerWidth - dropdownRect.width - 16;
      }
      
      dropdownRef.current.style.position = 'absolute';
      dropdownRef.current.style.left = `${finalLeft - buttonRect.left}px`;
      dropdownRef.current.style.top = '100%';
      dropdownRef.current.style.marginTop = '0.5rem'; // 8px 间距
    }
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
          containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className={cn("relative", className || "")} ref={containerRef}>
      {React.cloneElement(children as React.ReactElement, {
        onClick: () => setShowDropdown(!showDropdown),
      })}
      <div
        ref={dropdownRef}
        className={cn(
          "absolute bg-white rounded-md shadow-lg py-1 z-10",
          "min-w-[8rem] max-w-[24rem] whitespace-nowrap",
          "mt-2",
          "transition-opacity duration-200",
          showDropdown ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        )}
        style={{
          visibility: showDropdown ? 'visible' : 'hidden',
          position: 'absolute'
        }}
        onMouseLeave={() => setShowDropdown(false)}
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.type === "divider" && <DividerItem />}
            {item.type === "button" && (
              <ButtonItem
                text={item.text || ""}
                onClick={item.onClick || (() => {})}
              />
            )}
            {item.type === "text" && <TextItem text={item.text || ""} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
