import Image from "next/image";

const Logo = ({ className }: { className?: string }) => (
  <Image
    src="/logo256.png"
    alt="logo"
    width={24}
    height={24}
    className={className}
  />
);

export default Logo;
