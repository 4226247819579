import SLink from "../SLink";
import { SITES, SITE_ENDPOINTS } from "@/constants";

export default function Footer() {
  return (
    <footer className="bg-black">
      <div className="max-w-4xl px-6 mx-auto">
        {/* 添加支持的站点链接 section */}
        <div className="py-6 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-white mb-4">
            Supported Sites
          </h2>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
            {SITES.map((site) => (
              <SLink
                key={site}
                title={`${site} Video Downloader`}
                href={`/${SITE_ENDPOINTS[site.toLowerCase()]}`}
                className="text-sm text-gray-300 hover:text-white"
              >
                {site}
              </SLink>
            ))}
            <p className="text-sm text-gray-300 hover:text-white">
              and more...
            </p>
          </div>
        </div>

        <div className="py-6 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-white mb-4">
            Friendly Links
          </h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <SLink
              href="https://leapai.top"
              title="Interactive Neural Netwrok Tutorials"
            >
              Interactive Neural Netwrok Tutorials
            </SLink>
            <SLink
              href="https://ffmpeg-graph.site"
              title="FFmpeg Command Visualizer"
            >
              FFmpeg Command Visualizer
            </SLink>
          </div>
        </div>

        {/* 原有的 copyright 和链接部分 */}
        <div className="relative flex flex-col justify-between py-6 md:py-8 space-y-4 md:space-y-0 md:flex-row">
          <div className="text-sm text-gray-200 text-center md:text-left">
            Copyright © {new Date().getFullYear()} | SaaSphere, LLC
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 text-sm text-gray-200 items-center md:items-start">
            <SLink
              title="Contact Us"
              href="mailto:support@video-downloader.net"
            >
              Contact Us
            </SLink>
            <SLink title="Privacy Policy" href="/privacy-policy">
              Privacy Policy
            </SLink>
            <SLink title="Terms of Service" href="/terms-of-service">
              Terms of Service
            </SLink>
            <SLink title="Digital Millennium Copyright Act" href="/dmca">
              DMCA
            </SLink>
          </div>
        </div>
      </div>
    </footer>
  );
}
