import Link from "next/link";
import Logo from "@/components/icons/Logo";
import s from "./Navbar.module.css";
import Sites from "@/components/ui/Sites";
import SLink from "@/components/ui/SLink";
import Account from "@/components/ui/Account";

export default function Navlinks() {
  return (
    <div className="relative flex flex-row justify-between py-4 align-center md:py-6 text-white">
      <div className="flex items-center flex-1 gap-4">
        <Link
          href="/"
          title="Video Downloader"
          className={s.logo}
          aria-label="Logo"
        >
          <Logo className="w-10 h-10" />
        </Link>
        <Sites />
      </div>

      <div className="flex justify-end items-center space-x-6">
        <SLink title="Pricing" href="/pricing">
          Pricing
        </SLink>
        <Account />
      </div>
    </div>
  );
}
