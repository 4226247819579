const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN;
const API_DOMAIN = process.env.NEXT_PUBLIC_API_DOMAIN;
const BASE_URL = `https://${DOMAIN}`;

const SITES = [
  "YouTube",
  "TikTok",
  "Twitter",
  "Facebook",
  //   "Reddit",
  "Vimeo",
  "DailyMotion",
  //   "Twitch",
  "Instagram",
  "TED",
  "Bilibili",
  "Rumble",
];
const DEFAULT_SITE = SITES[0].toLowerCase();

const SITE_NAMES: Record<string, string> = {
  youtube: "YouTube",
  tiktok: "TikTok",
  twitter: "Twitter",
  facebook: "Facebook",
  reddit: "Reddit",
  vimeo: "Vimeo",
  dailymotion: "DailyMotion",
  twitch: "Twitch",
  instagram: "Instagram",
  ted: "TED",
  bilibili: "Bilibili",
  rumble: "Rumble",
};

const SITE_ENDPOINTS: Record<string, string> = {
  youtube: "yt-video-downloader",
  tiktok: "tiktok-video-downloader",
  twitter: "twitter-video-downloader",
  facebook: "facebook-video-downloader",
  reddit: "reddit-video-downloader",
  vimeo: "vimeo-video-downloader",
  dailymotion: "dailymotion-video-downloader",
  twitch: "twitch-video-downloader",
  instagram: "instagram-video-downloader",
  ted: "ted-video-downloader",
  bilibili: "bilibili-video-downloader",
  rumble: "rumble-video-downloader",
};

const SITE_TITLES: Record<string, string> = {
  youtube: "Free YouTube Video Downloader - Videos, Shorts, Music",
  tiktok: "Save TikTok Videos Without Watermark - Trending Content Downloader",
  twitter: "Twitter Video Saver - Download Viral Tweets & Social Media Content",
  facebook: "Facebook Video & Reels Downloader - Save Stories & Live Videos",
  reddit: "Reddit Video & Audio Downloader - Save Posts & Community Content",
  vimeo: "Vimeo HD Video Downloader - Professional & Creative Content Saver",
  dailymotion: "DailyMotion Video Grabber - Download Entertainment & News Videos",
  twitch: "Twitch Stream & VOD Downloader - Save Gaming Content & Live Streams",
  instagram: "Instagram Reels & Stories Saver - Download Social Media Content",
  ted: "TED Talks Downloader - Save Educational & Inspirational Presentations",
  bilibili: "Bilibili Video Saver - Download Anime & Entertainment Content",
  rumble: "Rumble Video Downloader - Save Alternative Media & Original Content",
};

const SITE_DOMAINS: Record<string, string> = {
  youtube: "youtube.com",
  tiktok: "tiktok.com",
  twitter: "x.com",
  facebook: "facebook.com",
  reddit: "reddit.com",
  vimeo: "vimeo.com",
  dailymotion: "dailymotion.com",
  twitch: "twitch.tv",
  instagram: "instagram.com",
  ted: "ted.com",
  bilibili: "bilibili.com",
  rumble: "rumble.com",
};

const SITE_PLACEHOLDERS: Record<string, string> = {
  youtube: "https://www.youtube.com/watch?v=<video_id>",
  tiktok: "https://www.tiktok.com/<username>/video/<video_id>",
  twitter: "https://x.com/<username>/status/<video_id>",
  facebook: "https://www.facebook.com/watch/?v=<video_id>",
  reddit: "https://www.reddit.com/r/AskReddit/comments/<video_id>",
  vimeo: "https://vimeo.com/<video_id>",
  dailymotion: "http://www.dailymotion.com/video/<video_id>",
  twitch: "https://www.twitch.tv/videos/<video_id>",
  instagram: "https://www.instagram.com/reel/<video_id>",
  ted: "https://www.ted.com/talks/<video_id>",
  bilibili: "https://www.bilibili.com/video/<video_id>",
  rumble: "https://rumble.com/<video_id>-<video_title>.html",
};

const SAMPLE_URLS: Record<string, string> = {
  youtube: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  tiktok: "https://www.tiktok.com/@mrcomfort22/video/7406858473620737311",
  twitter: "https://x.com/VigilantFox/status/1847429237369954709",
  facebook: "https://www.facebook.com/CatsZonePage/videos/228386674710203",
  reddit:
    "https://www.reddit.com/r/funny/comments/hlhy4n/by_far_one_of_the_funniest_videos_i_have_ever_seen",
  vimeo: "https://vimeo.com/102909164",
  dailymotion:
    "http://www.dailymotion.com/video/x5kesuj_office-christmas-party-review-jason-bateman-olivia-munn-t-j-miller_news",
  twitch: "http://www.twitch.tv/riotgames/v/6528877",
  instagram: "https://www.instagram.com/justinbieber/reel/C9Fu5I5oxBm",
  ted: "https://www.ted.com/talks/ken_robinson_do_schools_kill_creativity",
  bilibili: "https://www.bilibili.com/video/BV1wE4m1R7cu?p=1",
};

export {
  DOMAIN,
  API_DOMAIN,
  BASE_URL,
  DEFAULT_SITE,
  SITES,
  SITE_NAMES,
  SITE_ENDPOINTS,
  SITE_TITLES,
  SITE_DOMAINS,
  SITE_PLACEHOLDERS,
  SAMPLE_URLS,
};
