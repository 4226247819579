import { useContext } from "react";
import cn from "classnames";
import { MdAccountCircle, MdStars } from "react-icons/md";

import Popup from "@/components/ui/Popup";
import SLink from "@/components/ui/SLink";
import { GlobalStateContext, logout } from "@/utils/auth-helpers/cognito";

export default function Account({ className }: { className?: string }) {
  const globalState = useContext(GlobalStateContext);
  const isPro = globalState.equities?.level ?? 0 > 0;

  const handleSignOut = () => {
    logout();
    window.location.href = "/";
  };
  const goNewDownload = () => {
    window.location.href = "/home/new-download";
  };

  return globalState.authInfo ? (
    <Popup
      items={[
        { type: "button", text: globalState.authInfo.email!, onClick: goNewDownload  },
        { type: "divider" },
        { type: "button", text: "Sign Out", onClick: handleSignOut },
      ]}
    >
      {isPro ? (
        <MdStars className={cn("w-8 h-8 cursor-pointer text-yellow-400", className)} />
      ) : (
        <MdAccountCircle className={cn("w-8 h-8 cursor-pointer", className)} />
      )}
    </Popup>
  ) : (
    <SLink href={globalState.signInUrl}>Sign In</SLink>
  );
}
