"use client";
import { Equities } from "@/types/equities";
import jwt from "jsonwebtoken";
import { createContext } from "react";
import log from "@/utils/log";

export const COGNITO_DOMAIN = process.env.NEXT_PUBLIC_COGNITO_DOMAIN;
export const COGNITO_APP_CLIENT_ID =
  process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID;

// define AuthInfo type
export type AuthInfoIn = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  username: string;
  email: string;
  expires_in: number;
};

export type AuthInfo = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  username: string;
  email: string;
  access_token_expires: string;
};

export async function saveAuthInfo(data: AuthInfoIn): Promise<AuthInfo> {
  log("saveAuthInfo", Object.keys(data));

  // save auth info to local storage
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  localStorage.setItem("id_token", data.id_token);

  const idToken = data.id_token;
  const decodedToken = jwt.decode(idToken) as {
    "cognito:username"?: string;
    email?: string;
  };
  const username = decodedToken["cognito:username"] || "";
  const email = decodedToken.email || "";
  localStorage.setItem("username", username);
  localStorage.setItem("email", email);

  const accessTokenExpires = new Date(Date.now() + data.expires_in * 1000);
  localStorage.setItem(
    "access_token_expires",
    accessTokenExpires.toISOString()
  );

  return {
    access_token: data.access_token,
    refresh_token: data.refresh_token,
    id_token: data.id_token,
    username: username,
    email: email,
    access_token_expires: accessTokenExpires.toISOString(),
  };
}

export function getAuthInfo(): AuthInfo | null {
  const access_token = localStorage.getItem("access_token");
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  if (!access_token || !username || !email) {
    return null;
  }
  const authInfo = {
    access_token: access_token,
    refresh_token: localStorage.getItem("refresh_token"),
    id_token: localStorage.getItem("id_token"),
    username: username,
    email: email,
    access_token_expires: localStorage.getItem("access_token_expires"),
  } as AuthInfo;

  return authInfo;
}

export type GlobalState = {
  signInUrl: string;
  authInfo: AuthInfo | null;
  equities: Equities | null;
  onUpdateEquities: () => void;
};

export const GlobalStateContext = createContext<GlobalState>({
  signInUrl: "#",
  authInfo: null,
  equities: null,
  onUpdateEquities: () => {},
});

export function deleteAuthInfo() {
  log("deleteAuthInfo");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("access_token_expires");
}

export async function refreshToken(
  authInfo: AuthInfo
): Promise<AuthInfo | null> {
  const refreshToken = authInfo.refresh_token;
  if (!refreshToken) {
    return null;
  }

  log("refreshToken");

  const response = await fetch(`https://${COGNITO_DOMAIN}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: COGNITO_APP_CLIENT_ID as string,
      refresh_token: refreshToken,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    console.error("Failed to refresh token", JSON.stringify(data));
    deleteAuthInfo();
    return null;
  }

  return await saveAuthInfo(data);
}

export function loginUrl(): string {
  const redirectUri = `${window.location.origin}/auth/callback&state=${window.location.href}`;
  const url = `https://${COGNITO_DOMAIN}/oauth2/authorize?client_id=${COGNITO_APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUri}`;
  return url;
}

export async function logout() {
  log("logout");
  //   if (!authInfo.refresh_token) {
  //     deleteAuthInfo();
  //     return;
  //   }

  //   const response = await fetch(`https://${COGNITO_DOMAIN}/oauth2/revoke`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: new URLSearchParams({
  //       token: authInfo.refresh_token,
  //       client_id: COGNITO_APP_CLIENT_ID as string,
  //     }),
  //   });

  //   if (!response.ok) {
  //     const data = await response.json();
  //     log("signout error", data);

  //     deleteAuthInfo();
  //     return;
  //   }

  deleteAuthInfo();
}

export function isExpired(authInfo: AuthInfo): boolean {
  const accessTokenExpires = new Date(authInfo.access_token_expires as string);
  const currentTime = new Date();
  return currentTime > accessTokenExpires;
}
