import { useRouter } from "next/navigation";
import { SITES, SITE_ENDPOINTS } from "@/constants";
import { capitalize } from "@/utils/format";
import Popup from "@/components/ui/Popup";

export default function Sites({ className }: { className?: string }) {
  const router = useRouter();

  const onClick = (site: string) => {
    router.push(`/${SITE_ENDPOINTS[site.toLowerCase()]}`);
  };

  return (
    <Popup
      className={className}
      items={SITES.map((site) => ({
        type: "button",
        text: capitalize(site),
        onClick: () => onClick(site),
      }))}
    >
      <div className="flex items-center gap-1">
        <span>Sites</span>
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </Popup>
  );
}
