import Link from "next/link";

export default function SLink({
  children,
  href,
  title,
  rel,
  onClick,
  textColor = "text-white",
  className,
}: {
  children: React.ReactNode;
  href: string;
  title?: string;
  rel?: string;
  onClick?: () => void;
  textColor?: string;
  className?: string;
}) {
  return (
    <Link
      href={href}
      className={`${textColor} hover:text-blue-600 ${className??""}`}
      title={title}
      onClick={onClick}
      rel={rel}
    >
      {children}
    </Link>
  );
}
