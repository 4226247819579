export function formatDuration(duration: number) {
  if ((!duration || duration < 0) && duration !== 0) {
    return "unknown duration";
  }
  var segments = [];
  const hours = Math.floor(duration / 3600);
  if (hours > 0) {
    segments.push(`${hours}h`);
  }
  const minutes = Math.floor((duration % 3600) / 60);
  if (minutes > 0) {
    segments.push(`${minutes}m`);
  }
  const seconds = Math.floor(duration % 60);
  if (!segments.length || seconds > 0) {
    segments.push(`${seconds}s`);
  }
  return segments.join(" ");
}

export function formatFilesize(filesize: number) {
  if (!filesize || filesize <= 0) {
    return "unknown size";
  }
  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;
  let size = filesize;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  return `${size.toFixed(0)} ${units[unitIndex]}`;
}

export function formatNumber(n: number) {
  return new Intl.NumberFormat("en-US").format(n);
}

export function formatDate(dateString: string, time: boolean = false) {
  const date = new Date(
    parseInt(dateString.slice(0, 4)),
    parseInt(dateString.slice(4, 6)) - 1,
    parseInt(dateString.slice(6, 8)),
    parseInt(dateString.slice(9, 11)),
    parseInt(dateString.slice(11, 13)),
    parseInt(dateString.slice(13, 15))
  );
  return time ? date.toLocaleString() : date.toLocaleDateString();
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
