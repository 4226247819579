"use client";

import { useRef, useEffect, useState } from "react";
import localFont from "next/font/local";
import { usePathname } from "next/navigation";
import { GoogleAnalytics } from "@next/third-parties/google";

import "./globals.css";
import { DOMAIN, SITE_NAMES, SITE_TITLES } from "@/constants";

import Navbar from "@/components/ui/Navbar";
import Footer from "@/components/ui/Footer";
import DisplayAdUnit from "@/components/ui/DisplayAdUnit";

import {
  getAuthInfo,
  GlobalState,
  GlobalStateContext,
  loginUrl,
  isExpired,
  refreshToken,
} from "@/utils/auth-helpers/cognito";
import { authFetch } from "@/utils/api";
import log from "@/utils/log";

const geistSans = localFont({
  src: "./fonts/GeistVF.woff",
  variable: "--font-geist-sans",
  weight: "100 900",
});
const geistMono = localFont({
  src: "./fonts/GeistMonoVF.woff",
  variable: "--font-geist-mono",
  weight: "100 900",
});

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  log("root layout");

  // 获取当前路径
  const currentPath = usePathname();
  const isHome = currentPath.startsWith("/home");
  const isDownloader = currentPath.endsWith("video-downloader");
  let site = isDownloader
    ? currentPath.split("/")[1].replace("-video-downloader", "")
    : "";
  if (site === "yt") {
    site = "youtube";
  }

  const localState = useRef<GlobalState>({
    signInUrl: "#",
    authInfo: null,
    equities: null,
    onUpdateEquities: () => {},
  });
  const [globalState, setGlobalState] = useState<GlobalState>(
    localState.current
  );

  const fetchAuthInfo = async () => {
    log("fetching auth info...");
    let info = getAuthInfo();
    if (info && isExpired(info)) {
      log("token expired");
      info = await refreshToken(info);
    }
    localState.current.authInfo = info;
    // 修改这里，创建一个新的对象
    setGlobalState({ ...localState.current });
    log("auth info updated");

    // timeout to clear auth info if token expired
    if (info)
      setTimeout(
        () => {
          fetchAuthInfo();
        },
        info?.access_token_expires
          ? new Date(info.access_token_expires).getTime() - Date.now()
          : 1
      );
    return info;
  };

  const getEquities = async () => {
    const equitiesData = await authFetch({
      method: "GET",
      endpoint: "api/equities",
    });
    localState.current.equities = equitiesData.data;
    // 修改这里，创建一个新的对象
    setGlobalState({ ...localState.current });
    log("equites updated");
  };

  useEffect(() => {
    localState.current.signInUrl = loginUrl();
    localState.current.onUpdateEquities = getEquities;
    fetchAuthInfo().then((info) => {
      if (info) {
        getEquities();
      } else {
        log("no auth info");
      }
    });
  }, []);

  return (
    <html lang="en">
      <head>
        {isDownloader && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                name: `${SITE_NAMES[site]} Video Downloader`,
                applicationCategory: "MultimediaApplication",
                operatingSystem: "All",
                url: `https://${DOMAIN}${currentPath}`,
                description: SITE_TITLES[site],
              }),
            }}
          />
        )}
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.NEXT_PUBLIC_GOOGLE_AD_CLIENT}`}
          crossOrigin="anonymous"
        ></script>
      </head>
      <body
        className={`${geistSans.variable} ${geistMono.variable} antialiased`}
      >
        <GlobalStateContext.Provider value={globalState}>
          <div className="flex flex-col min-h-screen">
            {!isHome && <Navbar />}
            <div className="flex grow bg-gray-100 min-h-screen">
              <DisplayAdUnit adSlot="7599520584" className="hidden md:block" />
              <main className="grow">
                <div className="max-w-4xl mx-auto w-full">{children}</div>
              </main>
              <DisplayAdUnit adSlot="8673423949" className="hidden md:block" />
            </div>
            <Footer />
          </div>
        </GlobalStateContext.Provider>
      </body>
      <GoogleAnalytics gaId="G-FKZPY1F7GF" />
    </html>
  );
}
