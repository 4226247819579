"use client";

import React, { useEffect, useRef, useState } from "react";

import log from "@/utils/log";

interface DisplayAdUnitProps {
  adSlot: string;
  className?: string;
}

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[];
  }
}

const DisplayAdUnit: React.FC<DisplayAdUnitProps> = ({ adSlot, className = "" }) => {
  const loadCount = useRef(0);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  useEffect(() => {
    const loadAd = () => {
      if (isAdLoaded) return;

      loadCount.current++;
      try {
        if (loadCount.current < 5) {
          log("Ad container has width, loading ad");
          (window.adsbygoogle = window.adsbygoogle || []).push({});

          setIsAdLoaded(true);
          log("Ad loaded");
        } else {
          console.error("Failed to load ad after 5 attempts");
          setIsAdLoaded(true);
        }
      } catch (err) {
        console.warn("Error loading ad:", err);
        setTimeout(loadAd, 2000);
      }
    };

    loadAd();
  }, [isAdLoaded]);

  return (
    <div className={className}>
      <ins
        className="adsbygoogle block w-full min-w-32 min-h-32"
        data-ad-client={process.env.NEXT_PUBLIC_GOOGLE_AD_CLIENT}
        data-ad-slot={adSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default DisplayAdUnit;
