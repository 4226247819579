import { API_DOMAIN } from "../constants";
import log from "./log";
import { getAuthInfo, isExpired } from "./auth-helpers/cognito";


export function authFetch({
  method,
  endpoint,
  payload,
}: {
  method: string;
  endpoint: string;
  payload?: any;  // 使用 ? 标记为可选参数
}): Promise<any> {
  log("authFetch", method, endpoint, payload);
  const authInfo = getAuthInfo();

  // check expired
  if (!authInfo || isExpired(authInfo)) {
    // redirect to home
    window.location.href = "/";
    return Promise.reject(new Error("Token expired"));
  }
  let headers: Record<string, string> = {
    Authorization: `Bearer ${authInfo.id_token}`,
  };
  if (payload) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(`https://${API_DOMAIN}/${endpoint}`, {
    method: method,
    mode: "cors",
    credentials: "include",
    headers: headers,
    body: payload ? JSON.stringify(payload) : undefined,
  }).then((res) => res.json());
}
